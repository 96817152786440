<script>
import PageHeader from "@/components/page-header";
import FormOrderStatus from "@/views/pages/invoice-status/form.vue"
import { BredcrumpManagerEncode } from "@/common/bredcrumb-common"
import { networkRequesErrorResponseLogic } from "@/common/http-common-response-error"
import { ApiClientSelectedPlatform } from "@/common/http-common"
export default {
  components: {
    PageHeader, FormOrderStatus
  },
  data() {
    return {
      title: this.$t('orders.status.add'),
    };
  },
  methods: {
    addItem(data) {
      const self = this;
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
      var rs = JSON.parse(atob(this.$route.query.data))
      ApiClientSelectedPlatform(rs.platformId).post("/v1/admin/domains/item/order/status/item", data).then(function () {
        if (self.$route.query.data) {
          var rs = JSON.parse(atob(self.$route.query.data))
          if (rs.items) {
            self.$router.replace({ path: rs.items[rs.items.length - 2].path, query: { data: rs.items[rs.items.length - 2].data } }).catch(() => { });
          }
        }
      }).catch(
        function (error) {
          networkRequesErrorResponseLogic(self, error)
        }
      )
    }
  },
  mounted() {
    document.getElementById("preloader").style.display = "none";
    document.getElementById("status").style.display = "none";
    BredcrumpManagerEncode(this, { path: this.$route.path, title: 'orders.status.add' })
  }
};
</script>
<template>
  <div class="row">
    <div class="col-xl-12" style="padding: 0!important;">
        <PageHeader :title="title" />
      </div>
    <div class="col-xl-12">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ this.$t('orders.status.add') }}</h4>
              <p class="card-title-desc">
              </p>
              <div class="row">
                <FormOrderStatus :item="{ name: '', description: '', }" :type="'CREATE'"  @data="addItem"></FormOrderStatus>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>